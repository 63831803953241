<template>
    <PopupWrpr @close="close">
        <div class="popup__window-content">
            <div class="popup__window-header">
                <h2>{{ $translate('csv-example') }}</h2>
                <button type="button" class="popup__closer" @click="close">
                    <inline-svg :src="require(`@/assets/img/close-icon.svg`)"/>
                </button>
            </div>
            <div class="popup__window-body">
                <div class="fake-dropzone my-25">
                    <label>
                        <img src="img/upload-image-icon.svg" />
                        <span>{{ $translate('drag-&-drop-or-browse-to-choose-a-file') }}</span>
                        <input type="file" accept=".csv" @change="fileInput"/>
                        <button class="btn btn-primary btn-standart mt-10">
                            <inline-svg :src="require(`@/assets/img/upload-arrow.svg`)"/>
                            <span>{{ $translate('upload-csv') }}</span>
                        </button>
                    </label>
                </div>
                <!-- <input @input="test"/> -->
            </div>
        </div>
    </PopupWrpr>
</template>

<script>
import Base from "@/components/base";
import PopupWrpr from './PopupWrpr';

export default {
    name: "UploadCsv",
    components: {
        PopupWrpr,
    },
    data() {
        return {};
    },
    methods: {
        close() {
            this.$emit("close");
        },
        async fileInput(ev){
            let file=ev.target.files[0]
            var reader = new FileReader();
            reader.onload=(event)=>{
                this.$emit("submit",event.target.result)
                this.close()
            }
            reader.readAsText(file, "UTF-8");
        }
    },
};
</script>
