import AddProducts from './AddProducts.vue'
import ConfirmAction from './ConfirmAction.vue'
import AddIOTDevice from './AddIOTDevice.vue'
import RejectDispatch from './RejectDispatch.vue'
import QrCode from './QrCode.vue'
import QrCodeSettings from './QrCodeSettings.vue'
import CsvExample from './CsvExample.vue'
import UploadCsv from './UploadCsv.vue'
import AddSmartCondition from './AddSmartCondition.vue'
import AddBusinessCondition from './AddBusinessCondition.vue'
import SuccessAction from './SuccessAction.vue'
import FarmListDownload from './FarmListDownload.vue'
import AddDevice from './AddDevice.vue'
import AddNewProduct from './AddNewProduct.vue'
import AddItems from './AddItems.vue'

import AlertSMS from './AlertSMS.vue'
import AlertDashboard from './AlertDashboard.vue'
import AlertM2M from './AlertM2M.vue'
import AlertWebhook from './AlertWebhook.vue'
import AddBatch from './AddBatch.vue'
import AddParentProduct from './AddParentProduct.vue'
import RenamePopup from './RenamePopup.vue'

export default {
    AddProducts,
    ConfirmAction,
    AddIOTDevice,
    RejectDispatch,
    QrCode,
    QrCodeSettings,
    CsvExample,
    UploadCsv,
    AddSmartCondition,
    AddBusinessCondition,
    SuccessAction,
    FarmListDownload,
    AddDevice,
    AddNewProduct,
    AddItems,
    AlertSMS,
    AlertDashboard,
    AlertM2M,
    AlertWebhook,
    AddBatch,
    AddParentProduct,
    RenamePopup
}