<template>
	<PopupWrpr @close="close" class="danger-message">
		<div class="popup__window-content">
			<div class="popup__window-header">
				<h2>{{ title }}</h2>
				<button type="button" class="popup__closer" @click="close">
					<inline-svg :src="require('@/assets/img/close-icon.svg')"/>
				</button>
			</div>
			<div class="popup__window-body" v-if="message">
				<p class="popup__message">
					{{ message }}
				</p>
			</div>
			<div class="popup__window-actions">
				<button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="close">
					<span>{{ $translate('cancel') }}</span>
				</button>
				<button type="button" class="btn btn-primary btn-standart-2" @click="confirm" :class={processing}>
					<span>{{ $translate('confirm') }}</span>
				</button>
			</div>
		</div>
	</PopupWrpr>
</template>

<script>
import PopupWrpr from './PopupWrpr';

export default {
	name: 'ConfirmAction',
	props: ['title', 'message','processing'],
	components: {
		PopupWrpr,
	},
	methods: {
		close() {
			this.$emit('close');
		},
		async confirm() {
			await this.$emit('confirm');
			// this.close()
		}
	}
}
</script>