<template>
	<PopupWrpr @close="close">
		<div class="popup__window-content">
			<div class="popup__window-header">
				<h2>{{product? $translate('edit') : $translate('add') }} {{ $translate('parent-product') }}</h2>
                <button type="button" class="popup__closer" @click="close">
                    <inline-svg :src="require('@/assets/img/close-icon.svg')"/>
                </button>
			</div>
			<div class="popup__window-body">
				<form action="#">
					<div class="items-group cards">
						<div class="cards__col-6  mt-15">
							<div class="el-form">
								<label class="el-form__title">{{ $translate('product') }}</label>
								<div class="el-form__text" v-if="product">{{productName}}</div>

								<DropdownAdvanced
									v-else :options="productsTransform"
									v-model="payload.id"
									noDataText="No products created yet"
								/>
								
							</div>
						</div>
						<div class="cards__col-6  mt-15">
							<div class="el-form">
								<label class="el-form__title">{{ $translate('type-of-event') }}</label>
								<DropdownAdvanced 
									:options="events" :current="otherOpen ? 'other' : payload.type"
									@change="changeType"
								/>
							</div>
							<div class="el-form mt-15" v-if="otherOpen">
								<label class="el-form__title">Other:</label>
								<input type="text" class="input" v-model="payload.type">
							</div>
						</div>
					</div>
				</form>
			</div>
			<div class="popup__window-actions">
				<button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="close">
					<span>{{ $translate('cancel') }}</span>
				</button>
				<button type="submit" class="btn btn-primary btn-standart-2" @click="submit">
					<span>{{ $translate('submit') }}</span>
				</button>
			</div>
		</div>
	</PopupWrpr>
</template>

<script>
import Base from '@/components/base';
import PopupWrpr from './PopupWrpr';

import {
	devCats,
	devTypes,
	devTypesConfigs
} from '@/config';
import {eventTypes} from "@/config/products"

export default {
	name: 'AddParentProduct',
    props:["products",'product'],
	components: {
		...Base,
		PopupWrpr,
	},
    created(){
        if(this.product){
            this.payload.id=this.product.id
            this.payload.type=this.product.type
        }
    },
	data() {
		return {
			payload: {
                id:null,
                type:""
			},
			otherOpen: false,
		}
	},
	computed: {
        productsTransform(){
            return this.products.map(p=>{
                return {
                    ...p,
                    name:p.title
                }
            })
        },
        events(){
            return Object.entries(eventTypes).map(event=>{
				return {
					id:event[0],
					name:event[1]
				}
			})
        },
		productName(){
			return this.productsTransform.find(p=>p.id==this.payload.id)?.name||"none"
		}
	},
	watch: {
    	payload: {
			deep: true,
			handler(value){
				if(this.payload.type !== "" && this.events.map(item => item.id).includes(value.type)){
					if(value.type === 'other'){
						this.otherOpen = true;
						this.payload.type = "";
					} else {
						this.otherOpen = false;
					}
				}
			}
		},
	},
	methods: {
		submit() {
			// this.batch.id= ""+(new Date()).getTime()
			this.$emit('submit', this.payload);
            
		},
		close() {
			this.$emit('close');
		},
		changeType(value){
			this.payload.type = value.target.value.id;
		}
	},
	mounted(){
		if(this.payload.type !== "" && !this.events.map(item => item.id).includes(this.payload.type)){
			this.otherOpen = true;
		}
	}
}
</script>

<style scoped>
	.el-form__text{
		line-height: 24px;
		padding: 7px 0;
		min-width: 100px;
		text-align: left;
	}
</style>