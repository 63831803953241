<template>
	<PopupWrpr @close="close">
		<div class="popup__window-content">
			<div class="popup__window-header mb-30">
				<h2>{{ $translate('webhook') }}</h2>
				<button type="button" class="popup__closer" @click="close">
					<inline-svg :src="require('@/assets/img/close-icon.svg')"/>
				</button>
			</div>
			<div class="popup__window-body">
				<form action="#">
					<div class="form-group form-group--wrapper">
						<div class="form-group__row">
							<div class="form-group__col-5">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('display-name') }}</label>
									<input type="text" class="input" placeholder="Temperature warning" v-model="alertData.display_name">
								</div>
							</div>
							<div class="form-group__col-5">
								<div class="el-form">
									<label class="el-form__title el-form__title--flex">{{ $translate('callback-url') }} <span class="tooltip tooltip--icon" data-text="Title info"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M9.00034 9.59262L10.1066 8.97799C10.6587 8.67131 11.0012 8.08939 11.0012 7.45782C10.9358 6.40756 10.0351 5.60692 8.98438 5.66509C8.04556 5.62612 7.21189 6.26075 6.99951 7.17606" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
										<ellipse cx="9.0002" cy="8.99995" rx="7.50313" ry="7.50312" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></ellipse>
										<path d="M9.0837 12.2514C9.08364 12.2974 9.04631 12.3347 9.0003 12.3347C8.95429 12.3347 8.91699 12.2974 8.91697 12.2513C8.91695 12.2053 8.95421 12.168 9.00023 12.1679C9.02238 12.1679 9.04363 12.1767 9.05929 12.1924C9.07495 12.208 9.08373 12.2293 9.0837 12.2514" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
										</svg>
										</span>
									</label>
									<input type="text" class="input" placeholder="ex: https://example.com" v-model="alertData.callback_url">
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div class="popup__window-actions">
				<button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="close">
					<span>{{ $translate('cancel') }}</span>
				</button>
				<button type="submit" class="btn btn-primary btn-standart-2" @click="submit">
					<span>{{ $translate('submit') }}</span>
				</button>
			</div>
		</div>
	</PopupWrpr>
</template>


<script>
import Base from "@/components/base";
import PopupWrpr from './PopupWrpr';

export default {
	name: "AlertWebhook",
	props:["mode","alertsData","devices"],
    components:{
        ...Base,
		PopupWrpr,
    },
	watch: {
		alertsData: {
			immediate: true,
			handler:function (alertsData) {
				if(alertsData.display_name) this.alertData.display_name = alertsData.display_name
				if(this.mode=="edit"){
					this.alertData.callback_url = alertsData.callback_url
                    
				}
			}
		},
  	},
	data() {
        return{
            alertData:{
                display_name:null,

                callback_url:null
			}
		}
	},
	methods:{
		close(){
			this.$emit('close')
		},
        submit(){
		
            this.$emit('submit',"webhook",this.alertData)
        }
	}
}

</script>

