export const eventTypes = {
    "transformation": "Transformation",
    "aggregation": "Aggregation",
    "disaggregation": "Disaggregation",
    "purchase": "Purchase",
    "selling": "Selling",
    "receipt-of-raw-material": "Receipt of raw material",
    "collection-from-field": "Collection from field",
    "processing": "Processing",
    "production": "Production",
    "packaging": "Packaging",
    "other": "Other",
}
