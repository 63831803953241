<template>
	<PopupWrpr @close="close">
		<div class="popup__window-content">
			<div class="popup__window-header mb-30">
				<h2>{{ $translate('machine-to-machine') }}</h2>
					<button type="button" class="popup__closer" @click="close">
					<inline-svg :src="require('@/assets/img/close-icon.svg')"/>
				</button>
			</div>
			<div class="popup__window-body">
				<form action="#">
					<div class="form-group form-group--wrapper">
						<div class="form-group__row">
							<div class="form-group__col-5">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('display-name') }}</label>
									<input type="text" class="input" placeholder="Temperature warning" v-model="alertData.display_name">
								</div>
							</div>
						</div>
						<div class="form-group__row">
							<div class="form-group__col-5">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('select-device') }}</label>
									<DropdownAdvanced
										:options='devices'
										v-model="alertData.deviceID"
									></DropdownAdvanced>
								</div>
							</div>
							<div class="form-group__col-5">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('action') }}</label>
									<DropdownAdvanced
										:options='[{name:"Switch On",id:"switch_on"},{name:"Switch Off",id:"switch_off"}]'
										v-model="alertData.device_action"
									></DropdownAdvanced>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div class="popup__window-actions">
				<button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="close">
					<span>{{ $translate('cancel') }}</span>
				</button>
				<button type="submit" class="btn btn-primary btn-standart-2"  @click="submit">
					<span>{{ $translate('submit') }}</span>
				</button>
			</div>
		</div>
	</PopupWrpr>
</template>

<script>
import PopupWrpr from './PopupWrpr';
import Base from "@/components/base";

export default {
	name: "AlertM2M",
	props:["mode","alertsData","devices"],
    components:{
        ...Base,
		PopupWrpr,
    },
	watch: {
		alertsData: {
			immediate: true,
			handler:function (alertsData) {
				if(alertsData.display_name) this.alertData.display_name = alertsData.display_name
				if(this.mode=="edit"){
					this.alertData.deviceID = alertsData.deviceID
                    this.alertData.device_action = alertsData.device_action
				}
			}
		},
  	},
	data() {
        return{
            alertData:{
                display_name:null,

                deviceID: null,
				device_action: null,
			}
		}
	},
	methods:{
		close(){
			this.$emit('close')
		},
        submit(){
			if(!this.alertData.deviceID){
				this.alertData.device_action=null
			}
            this.$emit('submit',"m2m",this.alertData)
        }
	}
}

</script>
