<template>
	<PopupWrpr @close="close">
		<div class="popup__window-content">
			<div class="popup__window-header">
				<h2>{{title||"Add product"}}</h2>
				<button type="button" class="popup__closer" @click="close">
					<inline-svg :src="require('@/assets/img/close-icon.svg')"/>
				</button>
			</div>
			<div class="popup__window-body">
				<div class="items-group cards">
					<div class="cards__col-8 table-filter flex" >
						<label class="module__check check-input" >
							<input type="checkbox" :checked="isCheckedAll" @change="toggleAllCheckedInputs" />
							<span class="check"></span>
						</label>
						<form>
							<input type="text" class="input bg-left search-icon" :placeholder="$translate('search')" v-model="rowsFilters.search" >
						</form>
					</div>
				</div>
				<div class="data-row" v-for="p in filteredRows" :key="p.id">
					<ul class="data-row__list">
						<li class="data-row__item">
							<label class="module__check">
								<input type="checkbox" name="add-product" :checked="checkedInputs[p.id]" @change="setCheckedInputs($event,p.id)">
								<span class="check"></span>
								<span class="text">
									{{ p.title }}
								</span>
							</label>
						</li>
						<!-- <td class="data-row__item" style="display:block">
							<div class="long-text-wrapper" >
								<span class="text long-text-stroke">
									{{ p.id }}
								</span>
							</div>
						</td> -->
					</ul>
				</div>
				<PopupPagination v-model="filteredRows" :items="rawSource" />
			</div>
			<div class="popup__window-actions">
				<button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="close">
					<span>{{ $translate('cancel') }}</span>
				</button>
				<button type="button" class="btn btn-primary btn-standart-2" @click="submit" :class="{processing: processing}">
					<span>{{ $translate('submit') }}</span>
				</button>
			</div>
		</div>
	</PopupWrpr>
</template>

<script>
import Base from '@/components/base';
import {mixTables} from "@/mixins";
import PopupWrpr from './PopupWrpr';

export default {
	name: 'AddProducts',
	components: {
		...Base,
		PopupWrpr,
	},
	mixins:[mixTables],
	props: ["products","processing","title"],
	data() {
		return {
			source:"products",
			searchField:"title",
			currentCategoryId:null,
	
		}
	},

	computed:{
		sourceData(){
            return this.products || []
        },
	},
	methods: {
		close() {
			this.$emit('close')
		},
		submit() {
			this.$emit('submit', this.getCheckedInputs());
		},
		
	}
}
</script>

<style scoped>
	.long-text-stroke{
		white-space: nowrap; 
	}
	.check-input{
		margin-left: 20px;
	}
</style>
<style>
	
</style>