
<template>
    <PopupWrpr @close="close">
        <div class="popup__window-content">
            <div class="popup__window-header">
                <h2>{{title}}</h2>
                <button type="button" class="popup__closer" @click="close">
                    <inline-svg :src="require('@/assets/img/close-icon.svg')"/>
                </button>
            </div>
            <div class="popup__window-body">
                <div class="items-group cards">
                    <div class="cards__col-4">
                        <form action="">
                            <input type="text" class="input bg-left search-icon" placeholder="Search" v-model="rowsFilters.search" />
                        </form>
                    </div>
                </div>
                <div class="data-row" v-for="r in filteredRows" :key="r.id">
                    <ul class="data-row__list">
                        <li class="data-row__item">
                            <label class="module__check">
                                <input type="checkbox" name="add-device" v-model="checkedInputs[r.id]"/>
                                <span class="check"></span>
                                <slot name="firstColumn"  :r="r"></slot>
                                
                            </label>
                        </li>
                        <slot name="columns" :r="r"></slot>
                    </ul>
                </div>
                <PopupPagination v-model="filteredRows" :items="rawSource" />
            </div>
            <div class="popup__window-actions">
                <button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="close">
                    <span>{{ $translate('cancel') }}</span>
                </button>
                <button type="button" class="btn btn-primary btn-standart-2" @click="submit" :class="{processing}">
                    <span>{{ $translate('submit') }}</span>
                </button>
            </div>
        </div>
    </PopupWrpr>
</template>

<script>
import Base from "@/components/base";
import {mixTables} from "@/mixins";
import PopupWrpr from './PopupWrpr';

export default {
    name: "AddItems",
    components: {
        ...Base,
        PopupWrpr,
    },
    mixins:[mixTables],
    props: ["items","title","processing", "searchFieldProp"],
    data() {
        return {
            source:"devices",
            searchField: this.searchFieldProp || "name",
        };
    },

    computed: {
        sourceData(){
            return this.items || []
        }

    },
    methods: {
        close() {
            this.$emit("close");
        },
        submit() {
             this.$emit("submit", this.getCheckedInputs());
        },
      
    },
};
</script>
