<template>
<div class="wrapper_main">
	<main class="content">
		<div class="content-header">
            <div class="headline header-wrapper">
                <button type="button" @click="close" class="btn btn-w-shadow btn-sm btn-back btn-fix">
                        <inline-svg :src="require('@/assets/img/Arrow-prev.svg')" />
                </button>
                <div>
                    <h1 class="headline__title">{{ $translate('add-new-product-to-category') }}</h1>
                    <p class="headline__text">{{ $translate('fill-in-information-to-add-new-product') }}</p>
                </div>
            </div>
		</div>
		<div class="tabs-wrapper">
			<ul class="tabs">
				<li class="tabs__item">
					<a class="nav-tab" @click="switchTab('info')" :class="isActiveTab('info')">
						{{ $translate('basic-info') }}
					</a>
				</li>
				<li class="tabs__item">
					<a class="nav-tab" @click="switchTab('manufacture-info')" :class="isActiveTab('manufacture-info')">
						{{ $translate('manufacture-info') }}
					</a>
				</li>
				<li class="tabs__item">
					<a class="nav-tab" @click="switchTab('location')" :class="isActiveTab('location')">
						{{ $translate('location') }}
					</a>
				</li>
				<li class="tabs__item">
					<a class="nav-tab" @click="switchTab('device')" :class="isActiveTab('device')">
						{{ $translate('assign-device') }}
					</a>
				</li>
				<li class="tabs__item">
					<a class="nav-tab" @click="switchTab('parent')" :class="isActiveTab('parent')">
						{{ $translate('parent-products') }}
					</a>
				</li>
				<li class="tabs__item">
					<a class="nav-tab" @click="switchTab('farm')" :class="isActiveTab('farm')">
						{{ $translate('farm') }}
					</a>
				</li>
				<li class="tabs__item">
					<a class="nav-tab" @click="switchTab('code-settings')" :class="isActiveTab('code-settings')">
						{{ $translate('qr-code-settings') }}
					</a>
				</li>
			</ul>
		</div>
		<div class="content__body">
			<div class="tab-content">
				<div id="info" class="tab-content__item" :class="isActiveTab('info')">
					<div class="wizard-item footer-fixed">
						<div class="items-group cards ">
							<div class="cards__col-6 mt-15">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('category') }}</label>
									<div class="flex items-center">
										<DropdownAdvanced 
											:options="categories.map(c => ({ id: c.id, name: c.name }))"
											v-model="categoryProductsId"
										/>
									</div>
								</div>
							</div>
						</div>	
						<div class="items-group cards ">	
							<div class="cards__col-6">
								<div class="el-form mt-15 flex-auto">
									<label class="el-form__title">{{ $translate('product-name') }}*</label>
									<input type="text" class="input" v-model="product.title">
								</div>
							</div>
							<div class="cards__col-6 mt-15">
								<div class="select-group">
									<div class="el-form flex-auto">
										<label class="el-form__title">{{ $translate('quantity') }}*</label>
										<input type="number" class="input" v-model="product.quantity">
									</div>
									<div class="el-form flex-auto">
										<label class="el-form__title">{{ $translate('set-units') }}*</label>
										<input type="text" class="input" v-model="product.units">
									</div>
								</div>
							</div>
						</div>
						<div class="el-form mt-15">
							<label class="el-form__title">{{ $translate('description') }}*</label>
							<textarea class="textarea" :placeholder="$translate('write-product-description-here...')" v-model="product.description"></textarea>
						</div>
					</div>
				</div>
				<div id="manufacture-info" class="tab-content__item" :class="isActiveTab('manufacture-info')">
					<div class="wizard-item footer-fixed">
						<div class="items-group cards">
							<div class="cards__col-6">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('serial-number') }}</label>
									<input type="text" class="input" v-model="product.serial_number">
								</div>
							</div>
							<div class="cards__col-6">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('manufacturing-date') }}*</label>
									<label class="datepicker-el">
										<DatePicker v-model="product.manufacturing_date"/>
									</label>
								</div>
							</div>
							<div class="cards__col-6 mt-15">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('expiry-date') }}</label>
									<label class="datepicker-el">
										<DatePicker v-model="product.expiry_date"/>
									</label>
								</div>
							</div>
						</div>
						<div class="items-group cards border-top mt-15">
							<div class="cards__col-6 mt-15">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('invoice-number') }}</label>
									<input type="text" class="input" placeholder="12345" v-model="product.invoice_number">
								</div>
							</div>
							<div class="cards__col-6 mt-15">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('purchase-order') }}</label>
									<input type="text" class="input" placeholder="PO-12345" v-model="product.purchase_order">
								</div>
							</div>
						</div>
						<div class="items-group cards border-top mt-15">
							<div class="cards__col-6 mt-15">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('rfid-number') }}</label>
									<input type="text" class="input" placeholder="E0040100019D0CA1" v-model="product.rfid_number">
								</div>
							</div>
							<div class="cards__col-6 mt-15">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('batch-id') }}</label>
									<input type="text" class="input" placeholder="ARW004" v-model="product.batch_id">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="location" class="tab-content__item" :class="isActiveTab('location')">
					<div class="tab-content__block footer-fixed">
						<div class="map" style="border-radius: var(--border-radius-7); overflow: hidden;">
							<img src="@/assets/img/map-big.jpg" style="width: 100%;">
						</div>
					</div>
				</div>
				<div id="device" class="tab-content__item" :class="isActiveTab('device')">
					<div class="addition footer-fixed">
						<div class="headline-wrapper flex items-center">
							<div class="headline headline--sm flex-auto">
								<h2 class="headline__title">{{ $translate('assign-devices') }}</h2>
								<p class="headline__text">{{ $translate('here-you-can-find-devices-assigned-to-your-prouct') }}</p>
							</div>
							<WhitePlusButton @click="addDeviceOpen">{{ $translate('add-device') }}</WhitePlusButton>
						</div>
						<ul class="editable-list">
							<li class="editable-list__item" v-for="d in fieldValues('devices')" :key="d.id" >
								<div class="data-row">
									<ul class="data-row__list">
										<li class="data-row__item">{{d.name}}</li>
										<li class="data-row__item">
											<span class="data-row__icon">
												<DeviceIcon :category="d.category"></DeviceIcon>
											</span> 
											{{d.category}}
										</li>
										<li class="data-row__item">Italy, Rome</li>
									</ul>
									<div class="data-row__controls">
										<button type="button" class="btn btn-icon red-color"  @click="deleteFieldItem('devices',d.id)">
											<inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
										</button>
									</div>
								</div>
							</li>
						</ul>
						<WhitePlusButton  @click="addDeviceOpen">{{ $translate('add-device') }}</WhitePlusButton>
					</div>
				</div>
				<div id="parent" class="tab-content__item" :class="isActiveTab('parent')">
					<div class="addition footer-fixed">
						<div class="headline-wrapper flex items-center">
							<div class="headline headline--sm flex-auto">
								<h2 class="headline__title">{{ $translate('parent-products') }}</h2>
								<p class="headline__text">{{ $translate('here-you-can-find-parent-products-assigned-to-your-prouct') }}</p>
							</div>
							<WhitePlusButton>{{ $translate('add-product') }}</WhitePlusButton>
						</div>
						<ul class="editable-list">
							<li class="editable-list__item">
								<div class="data-row">
									<ul class="data-row__list">
										<li class="data-row__item">{{ $translate('product-name') }} 1</li>
										<li class="data-row__item">{{ $translate('transformation') }}</li>
									</ul>
									<div class="data-row__controls">
										<button type="button" class="btn btn-icon">
											<inline-svg :src="require(`@/assets/img/ico-gear.svg`)"/>
										</button>
										<button type="button" class="btn btn-icon red-color">
											<inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
										</button>
									</div>
								</div>
							</li>
						
						</ul>
						<WhitePlusButton>{{ $translate('add-product') }}</WhitePlusButton>
					</div>
				</div>
				<div id="farm" class="tab-content__item" :class="isActiveTab('farm')">
					<div class="addition footer-fixed">
						<div class="headline-wrapper flex items-center">
							<div class="headline headline--sm flex-auto">
								<h2 class="headline__title">{{ $translate('parent-products') }}</h2>
								<p class="headline__text">{{ $translate('here-you-can-find-parent-products-assigned-to-your-prouct') }}</p>
							</div>
							<div class="buttons-group add-buttons">
								<WhitePlusButton to="add-herd">{{ $translate('add-herd') }}</WhitePlusButton>
								<WhitePlusButton to="add-building">{{ $translate('add-building') }}</WhitePlusButton>
								<WhitePlusButton to="add-field">{{ $translate('add-field') }}</WhitePlusButton>
								<WhitePlusButton to="add-paddock">{{ $translate('add-paddock') }}</WhitePlusButton>
							</div>
						</div>
						<ul class="editable-list">
							<li class="editable-list__item">
								<div class="data-row">
									<ul class="data-row__list">
										<li class="data-row__item">Field Name 1</li>
										<li class="data-row__item">0.1 m<sub>2</sub></li>
									</ul>
									<div class="data-row__controls">
										<button type="button" class="btn btn-icon">
											<inline-svg :src="require(`@/assets/img/ico-gear.svg`)"/>
										</button>
										<button type="button" class="btn btn-icon red-color">
											 <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
										</button>
									</div>
								</div>
							</li>
							<li class="editable-list__item">
								<div class="data-row">
									<ul class="data-row__list">
										<li class="data-row__item">Herd Name 1</li>
										<li class="data-row__item">Type of Herd</li>
									</ul>
									<div class="data-row__controls">
										<button type="button" class="btn btn-icon">
											<inline-svg :src="require(`@/assets/img/ico-gear.svg`)"/>
										</button>
										<button type="button" class="btn btn-icon red-color">
											<inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
										</button>
									</div>
								</div>
							</li>
							<li class="editable-list__item">
								<div class="data-row">
									<ul class="data-row__list">
										<li class="data-row__item">Paddock Name 1</li>
										<li class="data-row__item">0.1 m<sub>2</sub></li>
									</ul>
									<div class="data-row__controls">
										<button type="button" class="btn btn-icon">
											<inline-svg :src="require(`@/assets/img/ico-gear.svg`)"/>
										</button>
										<button type="button" class="btn btn-icon red-color">
											<inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
										</button>
									</div>
								</div>
							</li>
						</ul>
						<div class="buttons-group add-buttons">
							<WhitePlusButton to="add-herd">{{ $translate('add-herd') }}</WhitePlusButton>
							<WhitePlusButton to="add-building">{{ $translate('add-building') }}</WhitePlusButton>
							<WhitePlusButton to="add-field">{{ $translate('add-field') }}</WhitePlusButton>
							<WhitePlusButton to="add-paddock">{{ $translate('add-paddock') }}</WhitePlusButton>
						</div>
					</div>
				</div>
				 <div id="code-settings" class="tab-content__item" :class="isActiveTab('code-settings')">
                        <div class="wizard-item footer-fixed">
                            <div class="headline headline--sm mt-15">
                                <h2 class="headline__title">{{ $translate('company-data') }}</h2>
                                <p class="headline__text">{{ $translate('select-info-that-you-want-to-show-in-qr-code') }}</p>
                            </div>
                            <div class="cards flex">
                                <div class="cards__col-4 cards__col-md-6 mt-15 flex flex-col">
                                    <label class="module__check disabled">
                                        <input type="checkbox" name="privacy_policy" checked />
                                        <span class="check"></span>
                                        <span class="text">{{ $translate('location') }}</span>
                                    </label>
                                    <label class="module__check disabled mt-15">
                                        <input type="checkbox" name="privacy_policy" checked />
                                        <span class="check"></span>
                                        <span class="text">{{ $translate('documents') }}</span>
                                    </label>
                                    <label class="module__check disabled mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">{{ $translate('vineyard-area') }}</span>
                                    </label>
                                    <label class="module__check disabled mt-15">
                                        <input type="checkbox" name="privacy_policy" checked />
                                        <span class="check"></span>
                                        <span class="text">{{ $translate('work-in-the-garden') }}</span>
                                    </label>
                                    <label class="module__check disabled mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">{{ $translate('stocking-density') }}</span>
                                    </label>
                                </div>
                                <div class="cards__col-4 cards__col-md-6 mt-15 flex flex-col">
                                    <label class="module__check disabled">
                                        <input type="checkbox" name="privacy_policy" checked />
                                        <span class="check"></span>
                                        <span class="text">{{ $translate('history-of-the-company') }}</span>
                                    </label>
                                    <label class="module__check disabled mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">{{ $translate('year-of-foundation') }}</span>
                                    </label>
                                    <label class="module__check disabled mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">{{ $translate('soils') }}</span>
                                    </label>
                                    <label class="module__check disabled mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">{{ $translate('cellar-work') }}</span>
                                    </label>
                                    <label class="module__check disabled mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">{{ $translate('certification') }}</span>
                                    </label>
                                </div>
                                <div class="cards__col-4 cards__col-md-6 mt-15 flex flex-col">
                                    <label class="module__check disabled">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">{{ $translate('media') }}</span>
                                    </label>
                                    <label class="module__check disabled mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">{{ $translate('bottle-production-/-year') }}</span>
                                    </label>
                                    <label class="module__check disabled mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">{{ $translate('age-vine') }}</span>
                                    </label>
                                    <label class="module__check disabled mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">{{ $translate('productivity') }}</span>
                                    </label>
                                    <label class="module__check disabled mt-15">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                        <span class="text">{{ $translate('philosophy') }}</span>
                                    </label>
                                </div>
                            </div>
                            <div class="cards items-group border-top mt-15">
                                <div class="cards__col">
                                    <div class="headline-wrapper flex items-center">
                                        <div class="headline headline--sm flex-auto mt-15">
                                            <h2 class="headline__title">{{ $translate('iot-date') }}</h2>
                                            <p class="headline__text">{{ $translate('select-info-that-you-want-to-show-in-qr-code') }}</p>
                                        </div>
                                    </div>
                                    <ul class="editable-list">
                                        <li class="editable-list__item">
                                            <div class="data-row flex-col has-spoiler">
                                                <div class="data-row__head flex w-full">
                                                    <ul class="data-row__list">
                                                        <li class="data-row__item">Thermometer Name 1</li>
                                                        <li class="data-row__item">
                                                            <span class="data-row__icon"><inline-svg :src="require(`@/assets/img/Temperature.svg`)"/></span> Temperature
                                                        </li>
                                                        <li class="data-row__item">Italy, Rome</li>
                                                    </ul>
                                                 
                                                </div>
                                                <div class="data-row__spoiler spoiler">
                                                    <div class="spoiler__button flex items-center">
                                                        <span class="flex-auto">{{ $translate('device-info') }}</span>
                                                        <inline-svg :src="require(`@/assets/img/Arrow-down.svg`)"/>
                                                    </div>
                                                    <div class="spoiler__body flex flex-col">
                                                        <label class="module__check disabled">
                                                            <input type="checkbox" name="privacy_policy" />
                                                            <span class="check"></span>
                                                            <span class="text">{{ $translate('temperature-at-the-day-of-product-creation') }}</span>
                                                        </label>
                                                        <label class="module__check disabled mt-15">
                                                            <input type="checkbox" name="privacy_policy" />
                                                            <span class="check"></span>
                                                            <span class="text">{{ $translate('temperature-during-the-day/week/month-of-the-product-creation') }}</span>
                                                        </label>
                                                        <label class="module__check disabled mt-15">
                                                            <input type="checkbox" name="privacy_policy" />
                                                            <span class="check"></span>
                                                            <span class="text">{{ $translate('temperature-during-the-smart-condition-in-the-dispatch') }}</span>
                                                        </label>
                                                        <label class="module__check disabled mt-15">
                                                            <input type="checkbox" name="privacy_policy" checked />
                                                            <span class="check"></span>
                                                            <span class="text">{{ $translate('max/min-value-of-the-temperature-during-a-time-range') }}</span>
                                                        </label>
                                                        <div class="cards flex" style="max-width: 630px">
                                                            <div class="cards__col-6 mt-15">
                                                                <div class="buttons-group">
                                                                    <label class="datepicker-el disabled">
                                                                        <input type="date" class="current-data" />
                                                                    </label>
                                                                    <div class="categories time-piker disabled dropdown-wrapper">
                                                                        <input type="time" class="time" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="cards__col-6 mt-15">
                                                                <div class="buttons-group">
                                                                    <label class="datepicker-el disabled">
                                                                        <input type="date" class="current-data" />
                                                                    </label>
                                                                    <div class="categories time-piker disabled dropdown-wrapper">
                                                                        <input type="time" class="time" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <label class="module__check disabled mt-15">
                                                            <input type="checkbox" name="privacy_policy" checked />
                                                            <span class="check"></span>
                                                            <span class="text">{{ $translate('exact-value-of-the-temperature-during-a-time-range') }}</span>
                                                        </label>
                                                        <div class="cards flex" style="max-width: 630px">
                                                            <div class="cards__col-6 mt-15">
                                                                <div class="buttons-group">
                                                                    <label class="datepicker-el disabled">
                                                                        <input type="date" class="current-data" />
                                                                    </label>
                                                                    <div class="categories time-piker disabled dropdown-wrapper">
                                                                        <input type="time" class="time" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="cards__col-6 mt-15">
                                                                <div class="buttons-group">
                                                                    <label class="datepicker-el disabled">
                                                                        <input type="date" class="current-data" />
                                                                    </label>
                                                                    <div class="categories time-piker disabled dropdown-wrapper">
                                                                        <input type="time" class="time" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="cards items-group border-top mt-15">
                                <div class="cards__col">
                                    <div class="headline headline--sm mt-15">
                                        <h2 class="headline__title">{{ $translate('product-chain-data') }}</h2>
                                        <p class="headline__text">{{ $translate('select-info-that-you-want-to-show-in-qr-code') }}</p>
                                    </div>
                                    <div class="cards flex">
                                        <div class="cards__col-4 flex flex-col">
                                            <label class="module__check disabled">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">{{ $translate('date-/-time-of-creation') }}</span>
                                            </label>
                                        </div>
                                        <div class="cards__col-4 flex flex-col">
                                            <label class="module__check disabled">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">{{ $translate('smart-rules-/-business-conditions') }}</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="cards flex">
                                        <div class="cards__col-4 flex flex-col mt-15">
                                            <label class="module__check disabled">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">{{ $translate('date-/-time-of-dispatch-dispatch') }}</span>
                                            </label>
                                        </div>
                                        <div class="cards__col-4 flex flex-col mt-15">
                                            <label class="module__check disabled">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">{{ $translate('date-/-time-of-dispatch-receive') }}</span>
                                            </label>
                                        </div>
                                        <div class="cards__col-4 flex flex-col mt-15">
                                            <label class="module__check disabled">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">{{ $translate('date-/-time-of-dispatch-return') }}</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="cards flex">
                                        <div class="cards__col-4 flex flex-col">
                                            <label class="module__check disabled mt-15">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">{{ $translate('transaction-history-of-the-product') }}</span>
                                            </label>
                                        </div>
                                        <div class="cards__col-4 flex flex-col">
                                            <label class="module__check disabled mt-15">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">{{ $translate('device-rule-log') }}</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="cards flex">
                                        <div class="cards__col-4 flex flex-col mt-15">
                                            <label class="module__check disabled">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">{{ $translate('basic-info-of-the-product') }}</span>
                                            </label>
                                        </div>
                                        <div class="cards__col-4 flex flex-col mt-15">
                                            <label class="module__check disabled">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">{{ $translate('parent-product(s)') }}</span>
                                            </label>
                                        </div>
                                        <div class="cards__col-4 flex flex-col mt-15">
                                            <label class="module__check disabled">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">{{ $translate('list-of-dispatches') }}</span>
                                            </label>
                                        </div>
                                        <div class="cards__col-4 flex flex-col mt-15">
                                            <label class="module__check disabled">
                                                <input type="checkbox" name="privacy_policy" />
                                                <span class="check"></span>
                                                <span class="text">{{ $translate('linked-iot-devices') }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
			</div>
		</div>
	</main>

	<FooterTabsControl
		:previous="true"
		cancelRoute="/products"
		:confirmName="$translate('create-a-product')"
		@confirm="createProduct"
		@swipeTab="swipeTab"
		:pageTabs="pageTabs"
		:activeTab="activeTab"
		:processing="processing"
	/>
	<!-- before translate confirmName="Create A Product" -->
	<AddDevice
		v-if="addDevicesPopupInfo.show"
		:devices="addDevicesPopupInfo.devices"
		@close="addDeviceClose"
		@submit="addDeviceSubmit"
		:title="$translate('assign-devices-to-product')"
	/>
	<!-- before translate title="Assign Device(s) to product" -->
	</div>
</template>

<script>
import { API, Storage } from 'aws-amplify';
import {
	createProduct,
	updateProduct,
	updateDevice,
	createProductsCategory
} from '@/graphql/mutations';

import {
	getProduct
} from '@/graphql/queries';

import Base from '@/components/base';
import Buttons from '@/components/buttons';
import Popups from '@/components/popups';
import { compareData } from '@/helpers'
import { mixTabs,mixProcessing,mixArrayFields } from "@/mixins";
export default {
	name: 'SingleProductAdd',
	components: {
		...Base,
		...Buttons,
		...Popups,

		
	},
	mixins:[mixTabs,mixProcessing,mixArrayFields],
	data() {
		return {
			product: {
				title: 'Untitled Product',
				status: 'active',
				companyProductsId: null
			},
			categoryProductsId:null,
			productAssignedDevices:[],

			pageTabs:['info', 'manufacture-info', 'location', 'device', 'parent', 'farm', 'code-settings'],
			//config mixArrayFields
			arrayFields:{
                devices:[]
            },
			
		}
	},

	computed: {
		edit() {
			return this.productID !== 'new'
		},
		companyData() {
			return this.$store.state.companyData || {}
		},
		categories() {
			return this.$store.state.categories || []
		},
		devices(){
			return this.$store.state.devices || []
		},

	},

	async created() {
		this.product.companyProductsId = this.companyData.id;
	},

	methods: {
		async createProduct() {
			this.processing = true;
			const input = this.product;
			const product = await API.graphql({
				query: createProduct,
				variables: { input }
			}).then(res => res.data.createProduct)


			if(this.categoryProductsId){
				await API.graphql({
					query: createProductsCategory,
					variables: {
						input: {
							productID: product.id,
							categoryID:  this.categoryProductsId           
						}
					}
				})
			}

			const requests = this.fieldArray('devices').map((deviceID) =>
                API.graphql({
                    query: updateDevice,
                    variables: {
                        input: {
                            id: deviceID,
               				productID: product.id
                        },
                    },
                }).then((res) => res.data.updateProduct)
            );
            await Promise.all(requests);
			await this.$store.dispatch("GET_DEVICES");

			this.product = product;

			this.$store.commit('ADD_ITEM', { field: 'products', item: product })

			this.processing = false;
			// const options={title:'',size:'sm',okLable:"To Products"} 
            // await this.$dialogs.alert("Product Added", options).then(res=>{
            // })

			this.$emit('confirm',product);
		},
		// async categoryChanged(ev) {
		// 	const { value } = ev.target;
		// 	const { id, name } = value;
		// 	console.log(value);

		// 	this.product.categoryProductsId = id;
		// },






        close(){
            this.$emit("close")
        }
	}
}
</script>

<style>
    .header-wrapper{
        display: flex;
        align-items: center;
    }
    .btn-fix{
        height: 36px;
        width: 36px;
        margin-right: 20px;
    }
</style>