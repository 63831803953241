<template>
    <PopupWrpr @close="close">
        <div class="popup__window-content">
            <div class="popup__window-header">
                <h2>{{ $translate('delete-product') }}</h2>
                <button type="button" class="popup__closer" @click="close">                    
                    <inline-svg :src="require(`@/assets/img/close-icon.svg`)"/>
                </button>
            </div>
            <div class="popup__window-body">
                <p class="popup__message">
                    {{ $translate('do-you-want-reject-a-dispatch?-you-can-modify-a-dispatch-before-reject-the-dispatch.') }}
                </p>
            </div>
            <div class="popup__window-actions">
                <button
                    type="button"
                    class="btn btn-w-shadow btn-standart-2 popup__closer"
                    @click="close"
                >
                    <span>{{ $translate('cancel') }}</span>
                </button>
                <div class="buttons-group">
                    <button
                        type="button"
                        class="btn btn-w-shadow btn-standart-2"
                        v-on:click="modify"
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.293 15.619L15.619 5.29299C16.009 4.90299 16.642 4.90299 17.032 5.29299L18.708 6.96899C19.098 7.35899 19.098 7.99199 18.708 8.38199L8.381 18.707C8.194 18.895 7.94 19 7.675 19H5V16.325C5 16.06 5.105 15.806 5.293 15.619Z"
                                stroke="currentColor"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ></path>
                            <path
                                d="M13.75 7.16003L16.84 10.25"
                                stroke="currentColor"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ></path>
                        </svg>
                        <span>{{ $translate('modify-a-dispatch') }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary btn-standart-2 ml-10"
                        @click="reject"
                    >
                        <span> {{ $translate('reject') }} </span>
                    </button>
                </div>
            </div>
        </div>
    </PopupWrpr>
</template>

<script>
import Base from '@/components/base';
import PopupWrpr from './PopupWrpr';

export default {
	name: 'RejectDispatch',
    components: {
        PopupWrpr,
    },
	data() {
        return{}
	},
	methods: {
		close() {
			this.$emit('close');
		},
        reject(){
            this.$emit('reject')
        },
        modify(){
            this.$emit('modify')
        }
	}
}
</script>
