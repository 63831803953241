<template>
	<PopupWrpr @close="close">
		<div class="popup__window-content">
			<div class="popup__window-header">
				<h2>{{ $translate('add-iot-device') }}</h2>
				<button type="button" class="popup__closer" @click="close">
					<inline-svg :src="require('@/assets/img/close-icon.svg')"/>
				</button>
			</div>
			<div class="popup__window-body">
				<form @submit.prevent="submit">
					<div class="items-group cards">
						<div :class="device.device_type=='gateway'||device.category=='custom_category'?'cards__col-6':'cards__col-4'">
							<div class="el-form mt-15 is-required">
								<label class="el-form__title">{{ $translate('device-name') }}</label>
								<input required type="text" class="input" v-model="device.name">
							</div>
						</div>
					
						<div :class="device.device_type=='gateway'||device.category=='custom_category'?'cards__col-6':'cards__col-4'">
							<div class="el-form mt-15">
								<label class="el-form__title">{{ $translate('device-type') }}</label>
								<DropdownAdvanced
									:options="deviceTypes"
									v-model="device.device_type"
								/>
							</div>
						</div>

						<div :class="device.category=='custom_category'?'cards__col-6':'cards__col-4' " v-if="device.device_type!='gateway'">
							<div class="el-form mt-15 is-required tutorial_popup_tooltip_1 tutorial_tooltip_top_center">
								<label class="el-form__title">{{ $translate('device-category') }}</label>
								<input :required="device.device_type!=='gateway'" type="text" class="required-dummy" v-model="device.category">
								<DropdownAdvanced
									:options="deviceCategories"
									v-model="device.category"
								/>
							</div>
						</div>
						<div v-if="device.category=='custom_category'" class="cards__col-6">
							<div class="el-form mt-15 is-required">
								<label class="el-form__title">{{ $translate('category-name') }}</label>
								<input type="text" class="input" v-model="device.custom_category">

							</div>
						</div>

					</div>
					<div class="el-form mt-15">
						<label class="el-form__title">{{ $translate('description') }}</label>
						<textarea class="textarea" :placeholder="$translate('description') + '...'" v-model="device.description"></textarea>
					</div>
					<div class="items-group cards border-top mt-15">
						<div class="cards__col-6">
							<div class="el-form mt-15">
								<label class="el-form__title">{{ $translate('date-of-manufacture') }}</label>
								<DatePicker v-model="device.date_of_manufacture" />
							</div>
						</div>
						<div class="cards__col-6">
							<div class="el-form mt-15">
								<label class="el-form__title">{{ $translate('date-of-purchase') }}</label>
								<DatePicker v-model="device.date_of_purchase" />
							</div>
						</div>
					</div>
					<div class="fieldes-actions active">
						<div class="items-group cards border-top mt-15">
							<div class="cards__col-6" v-for="c in deviceConfig" :key="c.field">
								<div class="el-form mt-15">
									<label class="el-form__title">{{ c.name }}</label>
									<Dropdown v-if="c.options"
										:key="device.config[c.field]"
										:options="c.options"
										:current="device.config[c.field]"
										:name="c.field"
										@change="changeConfigField"
									/>
									<input v-else type="text" class="input" v-model="device.config[c.field]">
								</div>
							</div>
						</div>
					</div>
					<div class="action tutorial_popup_tooltip_2 tutorial_tooltip_top_right">
						<button type="submit" class="btn btn-primary btn-standart" :class="{ processing }">
							<span>{{ $translate('add-iot-device') }}</span>
						</button>
					</div>
				</form>
			</div>
		</div>
	</PopupWrpr>
</template>

<script>
import Base from '@/components/base';
import PopupWrpr from './PopupWrpr';

import {
	devCats,
	devTypes,
	devTypesConfigs
} from '@/config';

export default {
	name: 'AddIOTDevice',
	components: {
		...Base,
		PopupWrpr,
	},
	data() {
		return {
			processing: false,
			device: {
				category: null,
				device_type: 'sensor',
				status: 'disabled',
				config: {
					getaway_id: null,
					band: null,
					
				},
				custom_category:null
			},
			
		}
	},
	computed: {
		userData() {
			return this.$store.state.userData || {}
		},
		companyData() {
			return this.$store.state.companyData || {}
		},
		deviceCategories() {
			return [...devCats,{id:"custom_category",name:"Add a new category"}]
		},
		deviceTypes() {
			return devTypes
		},
		deviceConfig() {
			return devTypesConfigs[this.device.device_type]
		}
	},
	methods: {
		changeConfigField(ev) {
			const { name, value } = ev.target;
			this.device.config[name] = value;
		},
		submit() {
			this.processing = true;
			this.device.companyID = this.companyData.id;
			this.device.userID = this.userData.id;		
			this.$emit('submit', this.device);
		},
		close() {
			this.$emit('close');
		}
	},
	created() {
		this.processing = false;
	},
}
</script>


