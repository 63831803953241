<template>
    <PopupWrpr @close="close" class="danger-message">
        <div class="popup__window-content">
            <div class="popup__window-header mb-30">
                <h2>{{ $translate('download') }}</h2>
                <button type="button" class="popup__closer" @click="close">
                    <inline-svg :src="require('@/assets/img/close-icon.svg')" />
                </button>
            </div>
            <div class="popup__window-body">
                <div class="el-form">
                    <label class="el-form__title">{{ $translate('choose-format') }}</label>
                    <Dropdown
                        :options="['PDF', 'Excel']"
                        :current="format"
                        name="format"
                        @change="updateFormatField"
                    />
                </div>
            </div>
            <div class="popup__window-actions">
                <button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="close">
                    <span>{{ $translate('cancel') }}</span>
                </button>
                <button type="button" class="btn btn-primary btn-standart-2" @click="confirm">
                    <span>{{ $translate('confirm') }}</span>
                </button>
            </div>
        </div>
    </PopupWrpr>
</template>

<script>
import Base from "@/components/base";
import PopupWrpr from './PopupWrpr';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from 'exceljs';
import { saveAs } from "file-saver";

export default {
    name: "FarmListDownload",
    components: {
        ...Base,
        PopupWrpr,
    },
    props: ['tableName'],
    data() {
        return {
            format:"PDF"
        };
    },
    methods: {
        close() {
            this.$emit("close");
        },
        async confirm() {
            if(this.format === 'PDF'){
                const doc = new jsPDF();
                doc.autoTable({ html: '.table' });
                doc.save(`${this.tableName ? this.tableName : 'table'}.pdf`);
            }

            if(this.format === 'Excel'){
                let tableElem = document.querySelector('.table');
                let tableContent = [];

                for (var i = 0, row; row = tableElem.rows[i]; i++) {
                    tableContent.push([]);
                    for (var j = 0, col; col = row.cells[j]; j++) {
                        if(!col.classList.contains('position-stiky')){
                            tableContent[i].push(col.textContent);
                        }
                    }  
                }

                const wb = new ExcelJS.Workbook();
                const ws = wb.addWorksheet(this.tableName ? this.tableName : 'table');
                ws.pageSetup.fitToPage = true;

                tableContent.forEach((item, index) => {
                    let r = ws.getRow(index+1);
                    r.values = item;
                })

                const buffer = await wb.xlsx.writeBuffer();
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                const fileExtension = '.xlsx';

                const blob = new Blob([buffer], {type: fileType});

                saveAs(blob, this.tableName ? this.tableName : 'table' + fileExtension);
            }

            this.$emit("confirm",this.format);
        },
        updateFormatField(ev) {
            const target = ev.target;
            const value = target.value;
            this.format = value;
        },
    },
};
</script>
