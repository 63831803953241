<template>
	<PopupWrpr @close="close">
		<div class="popup__window-content">
			<div class="popup__window-header mb-30">
				<h2>{{ $translate('rename-category') }}</h2>
				<button type="button" class="popup__closer" @click="close">
					<inline-svg :src="require('@/assets/img/close-icon.svg')"/>
				</button>
			</div>
			<div class="popup__window-body">
				<form action="#">
					<div class="form-group">
						<div class="form-group__row">
							<div class="form-group__col-10">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('group-name') }}</label>
									<input type="text" class="input" :placeholder="$translate('name')" v-model="name">
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div class="popup__window-actions">
				<button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="close">
					<span>{{ $translate('cancel') }}</span>
				</button>
				<button type="submit" class="btn btn-primary btn-standart-2" @click="submit" :class="{processing:processing}">
					<span>{{ $translate('submit') }}</span>
				</button>
			</div>
		</div>
	</PopupWrpr>
</template>


<script>
import Base from "@/components/base";
import PopupWrpr from './PopupWrpr';

export default {
	name: "RenamePopup",

	props:["currentName","processing"],
    components:{
        ...Base,
		PopupWrpr,
    },
	watch: {
		
  	},
	data() {
        return{
            name:null
		}
	},
    created(){
        this.name=this.currentName
    },
	methods:{
		close(){
			this.$emit('close')
		},
        submit(){
            this.$emit('submit',this.name)
        }
	}
}

</script>
