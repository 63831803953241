<template>
    <PopupWrpr @close="close" class="danger-message">
        <div class="popup__window-content">
            <div class="popup__window-header">
                <h2>{{ $translate('csv-example') }}</h2>
                <button type="button" class="popup__closer" @click="close">
                        <inline-svg :src="require(`@/assets/img/close-icon.svg`)"/>
                </button>
            </div>
            <div class="popup__window-body">
                <p class="popup__message">
                    {{ $translate('do-you-want-to-use-csv-example-for-this-dispatch?') }}
                </p>
            </div>
            <div class="popup__window-actions">
                <button
                    type="button"
                    class="btn btn-w-shadow btn-standart-2 popup__closer"
                    @click="close"
                >
                    <span>{{ $translate('cancel') }}</span>
                </button>
                <button
                    type="button"
                    class="btn btn-primary btn-standart-2"
                    @click="confirm"
                >
                    <span>{{ $translate('confirm') }}</span>
                </button>
            </div>
        </div>
    </PopupWrpr>
</template>

<script>
import PopupWrpr from './PopupWrpr';

export default {
    name: "CsvExample",
    components: {
        PopupWrpr,
    },
    data() {
        return {};
    },
    methods: {
        close() {
            this.$emit("close");
        },
        confirm() {
            this.$emit("confirm");
        },
    },
};
</script>
