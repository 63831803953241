<template>
    <PopupWrpr @close="close">
        <div class="popup__window-content w-450">
            <div class="popup__window-header justify-right">
                <button type="button" class="popup__closer" @click="close">
                    <inline-svg :src="require('@/assets/img/close-icon.svg')"/>
                </button>
            </div>
            <div class="popup__window-body" style="text-align: center;">
                <div class="icon icon-error">
                    <inline-svg :src="require('@/assets/img/success-arrow.svg')"/>
                </div>
                <h3>{{ message ? message : 'success!' }}</h3>
                <div class="click-ok">{{ $translate('click-ok-to-proceed') }}</div>
                <button type="button" @click="close" class="btn btn-primary btn-standart-2">{{ $translate('ok') }}</button>
            </div>
        </div>
    </PopupWrpr>
</template>

<script>
import PopupWrpr from './PopupWrpr';

export default {
    components: {
        PopupWrpr,
    },
    props:["to", "message"],
    methods: {
        close() {
            this.$emit("close");
            this.$router.push(this.to)
        },
    },
};
</script>


<style scoped>
    .popup__window-body h3{
        padding-bottom: 10px;
    }
    .click-ok{
        margin-bottom: 25px;
        font-size: 14px;
    }

    .popup__window-body{
        padding-bottom: 35px;
    }
</style>