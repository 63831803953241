<template>
	<PopupWrpr @close="close">
		<div class="popup__window-content">
			<div class="popup__window-header mb-30">
				<!-- <h2>SMS / Email</h2> -->
				<h2>{{ $translate('email-alert') }}</h2>
				<button type="button" class="popup__closer" @click="close">
					<inline-svg :src="require('@/assets/img/close-icon.svg')"/>
				</button>
			</div>
			<div class="popup__window-body">
				<form action="#">
					<div class="form-group">
						<!-- <div class="form-group__row">
							<div class="form-group__col-5">
								<div class="el-form">
									<label class="el-form__title">Display Name</label>
									<input type="text" class="input" placeholder="Temperature warning" v-model="alertData.display_name">
								</div>
							</div>
						</div> -->
						<div class="form-group__row">
							<div class="form-group__col-5">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('display-name') }}</label>
									<input type="text" class="input" placeholder="Temperature warning" v-model="alertData.display_name">
								</div>
							</div>
							<div class="form-group__col-5" v-if="false">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('select-mode') }}</label>
                                    <DropdownAdvanced :options="[{name:'Email',id:'email'},{name:'SMS',id:'sms'}]" v-model="alertData.mode"></DropdownAdvanced>
									<!-- <div class="categories dropdown-wrapper">
										<button class="btn btn-standart dropdown-btn none-shadow">
											<span class="active-categories">Email</span>
											<span>
												<img src="img/Arrow-down.svg">
											</span>
										</button>
										<div class="categories__dropdown dropdown-content">
											<ul>
												<li><span>Email</span></li>
												<li><span>SMS</span></li>
											</ul>
										</div>
									</div> -->
								</div>
							</div>
							<div class="form-group__col-5" v-if="alertData.mode=='email'">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('email') }}</label>
									<input type="text" class="input" placeholder="tim.jennings@example.com" v-model="alertData.email">
								</div>
							</div>
                            <div class="form-group__col-5" v-if="alertData.mode=='sms'">
								<div class="el-form">
									<label class="el-form__title" >{{ $translate('telephone') }}</label>
									<input type="text" class="input" placeholder="+3003014122" v-model="alertData.tel">
								</div>
							</div>
						</div>
						<div class="form-group__row">
							<div class="form-group__col-10">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('note') }}</label>
									<textarea class="textarea" placeholder="You should check the device!" v-model="alertData.note"></textarea>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div class="popup__window-actions">
				<button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="close">
					<span>{{ $translate('cancel') }}</span>
				</button>
				<button type="submit" class="btn btn-primary btn-standart-2" @click="submit">
					<span>{{ $translate('submit') }}</span>
				</button>
			</div>
		</div>
	</PopupWrpr>
</template>


<script>
import Base from "@/components/base";
import PopupWrpr from './PopupWrpr';

export default {
	name: "AlertSMS",
	props:["mode","alertsData"],
    components:{
        ...Base,
		PopupWrpr,
    },
	watch: {
		alertsData: {
			immediate: true,
			handler:function (alertsData) {
				if(alertsData.display_name) this.alertData.display_name = alertsData.display_name
				if(alertsData.note) this.alertData.note = alertsData.note
				if(this.mode=="edit"){
					this.alertData.mode=alertsData.mode
                    this.alertData.email=alertsData.email
                    this.alertData.tel=alertsData.tel
				}
			}
		},
  	},
	data() {
        return{
            alertData:{
                display_name:null,
                note:null,
                tel:null,
                mode:'email',
                email:null,
			}
		}
	},
	methods:{
		close(){
			this.$emit('close')
		},
        submit(){
            if(this.alertData.mode=="email"){
                this.alertData.tel=null
            }
            if(this.alertData.mode=="sms"){
                this.alertData.email=null
            }
            this.$emit('submit',"sms_email",this.alertData)
        }
	}
}

</script>
