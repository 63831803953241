<template>
    <PopupWrpr @close="close">
        <div class="popup__window-content">
            <div class="popup__window-header">
                <h2>{{ $translate('qr-code') }}</h2>
                <button type="button" class="popup__closer" @click="close">
                    <inline-svg :src="require(`@/assets/img/close-icon.svg`)"/>
                </button>
            </div>
            <div class="popup__window-body py-25">
                <div class="popup__img">
                    <!-- <img src="img/qr-code-big.svg" alt="" /> -->
                    <!-- <VueQRCodeCompo/nent :text="'asdasdqwweqe'"></VueQRCodeComponent> -->
                    <div class="qr_code_box relative" style="padding: 10px;" @click="downloadQR">
                        <div class="qr_code_corners_wrpr">
                            <span class="qr_code_corners" v-for="i in 4" :key="i"></span>
                            <VueQRCodeComponent
                                :text="generateCodeLink()"
                                :size='180'
                                error-level="L"
                            />
                        </div>
                        <div class="click_to_dwnld">{{ $translate('click-to-download-png-qr-code-file') }}</div>
                    </div>
                </div>
                <template v-if="type==='createDisaptchQrCode'">
                    <p class="popup__message">
                        {{ $translate('this-is-the') }} <a :href="generateCodeLink()" target="_blank" style="color: var(--text-primary);">{{ $translate('qr-code') }}</a> {{ $translate('linked-to-this-dispatch.-attach-it-to-your-shipment-or-send-it-to-the-receiving-user') }}
                    </p>
                    <div class="action" style="margin-bottom: 0"><button @click="close" type="submit" class="btn btn-primary btn-standart"><span>{{ $translate('ok') }}</span></button></div>
                </template>
                <p class="popup__message" v-else>
                    {{ $translate('scan') }} <a :href="generateCodeLink()" target="_blank" style="color: var(--text-primary);">{{ $translate('qr-code') }}</a> {{ $translate('for-more-information-about-product') }}
                </p>
            </div>
        </div>
    </PopupWrpr>
</template>

<script>
import Base from '@/components/base';
import VueQRCodeComponent from 'vue-qrcode-component';
import PopupWrpr from './PopupWrpr';

export default {
	name: 'QrCode',
    props:['product','link','fullLink','type'],
    components:{
        VueQRCodeComponent:VueQRCodeComponent,
        PopupWrpr,
    },
	methods: {
        generateCodeLink(){
            if(this.fullLink){
                console.log('fullLink',this.fullLink);
                return this.fullLink
            }
            let link=`${window.location.origin}/qr-mobile`;
            let mainParams= Object.entries({"productID":this.product.id,companyID:this.product.companyProductsId}).map(entry=>entry.join("=")).join('&')
            let settingParams=[] 
            Object.entries(this.product.qr_settings)
                .forEach(entry=>{
                    if(entry[1]){
                        if(entry[0]=='location'){
                            settingParams.push('address') 
                        }else{
                            settingParams.push(entry[0]) 
                        }
                    }
                })
            let fullLink=link+"?"+mainParams+"&"+settingParams.join('&')
            console.log("ss",fullLink,this.product.gr_settings);
            return encodeURI(fullLink)
        },
		close() {
			this.$emit('close');
		},
        downloadQR(){
            var url = document.querySelector('.qr_code_corners_wrpr img').src.replace(/^data:image\/[^;]+/, 'data:application/octet-stream');

            var element = document.createElement('a');
            element.setAttribute('href', url);
            element.setAttribute('download', `${this.product ? this.product.title : new Date().toDateString() }-qr-code.png`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
	}
}
</script>
