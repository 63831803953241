<template>
	<PopupWrpr @close="close">
		<div class="popup__window-content">
			<div class="popup__window-header mb-30">
				<h2>{{ $translate('dashboard') }}</h2>
				<button type="button" class="popup__closer" @click="close">
					<inline-svg :src="require('@/assets/img/close-icon.svg')"/>
				</button>
			</div>
			<div class="popup__window-body">
				<form action="#">
					<div class="form-group form-group--wrapper">
						<div class="form-group__row">
							<div class="form-group__col-10">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('display-name') }}</label>
									<input type="text" class="input" placeholder="Temperature warning" v-model="alertData.display_name">
								</div>
							</div>
						</div>
						<div class="form-group__row">
							<div class="form-group__col-10">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('note') }}</label>
									<textarea class="textarea" placeholder="You should check the device!" v-model="alertData.note"></textarea>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div class="popup__window-actions">
				<button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="close">
					<span>{{ $translate('cancel') }}</span>
				</button>
				<button type="submit" class="btn btn-primary btn-standart-2" @click="submit">
					<span>{{ $translate('submit') }}</span>
				</button>
			</div>
		</div>
	</PopupWrpr>
</template>

<script>
import Base from "@/components/base";
import PopupWrpr from './PopupWrpr';

export default {
	name: "AlertDashboard",

	props:["mode","alertsData"],
    components:{
        ...Base,
		PopupWrpr,
    },
	watch: {
		alertsData: {
			immediate: true,
			handler:function (alertsData) {
				if(alertsData.display_name) this.alertData.display_name = alertsData.display_name
				if(alertsData.note) this.alertData.note = alertsData.note
			}
		},
  	},
	data() {
        return{
            alertData:{
                display_name:null,
                note:null,
			}
		}
	},
	methods:{
		close(){
			this.$emit('close')
		},
        submit(){
            this.$emit('submit',"dashboard",this.alertData)
        }
	}
}

</script>