<template>
	<PopupWrpr @close="close">
		<div class="popup__window-content">
			<div class="popup__window-header">
				<h2>{{ $translate('add-batch') }}</h2>
				<button type="button" class="popup__closer" @click="close">
					<inline-svg :src="require('@/assets/img/close-icon.svg')"/>
				</button>
			</div>
			<div class="popup__window-body">
				<form @submit.prevent="submit">
					<div class="items-group cards">
						<div class="cards__col-6">
							<div class="el-form mt-15">
								<!-- <label class="el-form__title">{{ $translate('batch-name') }}</label> -->
								<label class="el-form__title">Batch name</label>
								<input required type="text" class="input" v-model="batch.name">

							</div>
						</div>
						
					</div>
				
					<div class="action">
						<button type="submit" class="btn btn-primary btn-standart">
							<span>{{ $translate('add-batch') }}</span>
						</button>
					</div>
				</form>
			</div>
		</div>
	</PopupWrpr>
</template>

<script>
import Base from '@/components/base';
import PopupWrpr from './PopupWrpr';

import {
	devCats,
	devTypes,
	devTypesConfigs
} from '@/config';

export default {
	name: 'AddIOTDevice',
	components: {
		...Base,
		PopupWrpr,
	},
	data() {
		return {
			batch: {
                name:null
			},
		}
	},
	computed: {
	
	},
	methods: {
		submit() {
			this.batch.id= ""+(new Date()).getTime()
			this.$emit('submit', this.batch);
		},
		close() {
			this.$emit('close');
		}
	}
}
</script>